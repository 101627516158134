/* WARNING: Auto-generated by the import-fonts script. Changes here will be overwritten. */

@font-face {
  font-family: "Spectral";
  src: url("./Spectral-Medium/spectral-medium.woff2") format("woff2"),
    url("./Spectral-Medium/spectral-medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Instrument Sans";
  src: url("./InstrumentSans-SemiBold/instrument-sans-semi-bold.woff2") format("woff2"),
    url("./InstrumentSans-SemiBold/instrument-sans-semi-bold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Instrument Sans";
  src: url("./InstrumentSans-Regular/instrument-sans-regular.woff2") format("woff2"),
    url("./InstrumentSans-Regular/instrument-sans-regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}
